import { Title } from '@solidjs/meta';
import { Heading, NavigationMenu, NavigationMenuItem } from '@troon/ui';
import { IconFlag, IconGlobe, IconMap } from '@troon/icons';
import { useLocation } from '@solidjs/router';
import { StyleCard } from '../_style-card';

export default function MenusPage() {
	const location = useLocation();
	return (
		<>
			<Title>Menus | Interactive | Style Guide | Troon</Title>
			<Heading as="h1">Menus</Heading>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="NavigationMenu">
					<NavigationMenu trigger="Toggle menu">
						<NavigationMenuItem icon={IconMap} href="#1">
							Open…
						</NavigationMenuItem>
						<NavigationMenuItem icon={IconFlag} href="#2">
							New…
						</NavigationMenuItem>
						<NavigationMenuItem icon={IconGlobe} href={location.pathname}>
							Current page
						</NavigationMenuItem>
					</NavigationMenu>
				</StyleCard>
			</div>
		</>
	);
}
